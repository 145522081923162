/* Main Page Layout */
.artists-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--dark-1);
}

.dark-mode .artists-page {
    color: var(--light-1);
}

.artists-page__header {
    width: 100%;
    font-weight: bold;
}

.artists-page__body {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.artists-page__featured-artists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.featured-artists__header {
    width: 100%;
}

.featured-artists__list {
    max-width: 960px;

    font-size: 100%;
    font-weight: 400;

    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;

    display: flex;
    flex-wrap: wrap;
}

.featured-artists__list-item {
    text-decoration: none;
    color: var(--dark-1);
    margin: 0.5em;
    max-width: 14em;
}

.featured-artists__list-item:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.dark-mode .featured-artists__list-item {
    color: var(--light-1);
}

.featured-artists__list-item-image > img {
    width: 100%;
    max-width: 300px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 5px;
}

.featured-artists__list-item-artist-name {
    font-size: 1.2em;
    text-align: center;
    margin: 1%;
    display: block;
    color: inherit;
}

/* Search Artists */

.letter-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: var(--dark-1);
    width: 100%;
    padding: 5px 15px;
    border-radius: 5px;

    margin-top: 10px;
}

.dark-mode .letter-bar {
    background-color: var(--dark-2);
}

.letter-bar__letter {
    font-size: 20px;
    color: var(--light-1);
    padding: 5px;
}

.letter-bar__letter:hover {
    cursor: pointer;
}

.is-selected {
    color: black;
    background-color: var(--light-1);
    font-weight: bold;
    border-radius: 5px;
}

.search-artists__search-bar {
    display: flex;
    justify-content: center;
}

.artists-page__search-artists {
    width: 100%;

    margin-bottom: 25px;

    /* border: 3px solid black; */
    padding: 5px;
}

.search-arists__search-results {
    border: 3px solid var(--dark-1);

    display: flex;
    flex-flow: row wrap;
    height: 20em;

    justify-content: center;
    align-content: flex-start;

    overflow: auto;

    margin-top: 10px;
}

.dark-mode .search-arists__search-results {
    border-color: var(--dark-2);
}

.search-arists__search-results p {
    color: var(--dark-1);
}

.dark-mode .search-arists__search-results p {
    color: var(--light-1);
}

.artist-search__search-result {
    border: 3px solid var(--dark-1);

    flex: 30% 0 0;
    width: max-content;

    display: flex;
    justify-content: center;

    margin: 5px;

    font-size: 16px;
    text-align: center;

    background-color: transparent;

    height: min-content;

    text-decoration: none;

    color: inherit;
}

.artist-search__search-result:hover {
    opacity: 0.5;
    transition-duration: 0.25s;
}

.dark-mode .artist-search__search-result {
    border-color: var(--gray-1);
    color: var(--light-1)
}

.search-result__name {
    color: inherit;
}

.search-artists__search-form {
    display: flex;
    flex-direction: row;
}

.search-artists__search-form > input{
    background-color: var(--light-1);
    font-size: 16px;
    border: 2px solid var(--dark-1);
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
}

.dark-mode .search-artists__search-form > input {
    background-color: var(--dark-2);
    color: var(--light-1);
    border-color: var(--gray-1);
}

/* Responsive */
@media screen and (max-width: 850px) {

    .artists-page__body {
        width: 90%;
    }

    .featured-artists__list {
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
    }

    .artist-search__search-result {
        flex: 100% 0 0;
    }

    .search-artists__search-form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .search-artists__search-form > button {
        width: fit-content;
    }

    .search-artists__search-form > input {
        margin-bottom: 10px;
    }

    .letter-bar {
        width: 90%;

        overflow: auto;
    }

}
