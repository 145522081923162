.about-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dark-mode .about-page {
    color: var(--light-1)
}

.about-page__body {
    width: 97%;
}

.about-page__body p {
    color: inherit
}
