.popup-background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.popup-container-for-delete {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    text-align: center;
    width: 35%;
    height: 55vh;
    translate: 96% 30% 0px;
}

.popup-container-for-delete-artist {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    text-align: center;
    width: 35%;
    height: 45vh;
    translate: 96% 50% 0px;
}

.popup-container-for-delete-video {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    text-align: center;
    width: 35%;
    height: 72vh;
    translate: 96% 25% 0px;
}

.remove-popup-container {
    margin: 0;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
}

.remove-popup-button {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    color: black;
}

.user-info-container {
    text-align: center;
    margin-top: 1rem;
    line-height: 3rem;
}

.user-info {
    font-size: 1.5rem !important;
}

.confirm-delete-message {
    text-align: center;
    margin-top: 1rem;
    line-height: 2rem;
    font-style: italic;
}

.button-section {
    margin-top: 2rem;
    text-align: center;
}

.increase-size {
    font-size: 1.25rem;
    color: white;
    background-color: #EA100B;
}

.message-box {
    margin: 0rem 1rem;
    margin-top: 0.5rem;
    padding: 0.5rem 0.5rem;
    border: 1px solid black;
    border-radius: 5px;
}

.red {
    background-color: #ffdce0;
}

.green {
    background-color: #D1ECC1;
}

@media only screen and (max-height: 779px) {
    .popup-container-for-delete h1 {
        margin: 0rem 0rem;
        font-size: 1.5rem;
    }

    .popup-container-for-delete-artist h1 {
        margin: 0rem 0rem;
        font-size: 1.5rem;
    }

    .user-info-container {
        margin: 0rem 0rem;
        line-height: 2rem;
    }

    .user-info {
        font-size: 1.1rem !important;
    }
}

@media only screen and (max-height: 650px) {
    .popup-container-for-delete h1 {
        margin: 0rem 0rem;
        font-size: 1.4rem;
    }

    .popup-container-for-delete-artist h1 {
        margin: 0rem 0rem;
        font-size: 1.5rem;
    }

    .user-info-container {
        margin: 0rem 0rem;
        line-height: 1.5rem;
    }

    .user-info {
        font-size: 1rem !important;
    }
}

@media only screen and (min-height: 1080px) {
    .popup-container-for-delete {
        height: 50vh;
        translate: 96% 30% 0px;
    }

    .user-info {
        font-size: 2rem !important;
    }

    .confirm-delete-message {
        margin-top: 2rem;
    }

    .quick-action-button.increase-size {
        font-size: 2rem;
    }

    .message-box {
        margin-top: 2rem;
    }

    .popup-container-for-delete-video {
        height: 60vh;
        translate: 96% 25% 0px;
    }
}

@media only screen and (min-width: 1920px) {
    .popup-container-for-delete {
        height: 50vh;
        translate: 96% 30% 0px;
    }

    .user-info {
        font-size: 2rem !important;
    }

    .confirm-delete-message {
        margin-top: 2rem;
    }

    .quick-action-button.increase-size {
        font-size: 2rem;
    }

    .message-box {
        margin-top: 2rem;
    }

    .popup-container-for-delete-video {
        height: 60vh;
        translate: 96% 25% 0px;
    }
}