.tags__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.tag {
    background-color: #c06b2a;
    color: white;
    border-radius: 5px;
    padding: 0.5em;
    margin: 5px;
    font-weight: 500;
    font-family: inherit;
}

.tags__items a {
    text-decoration: none;
}

.tag:hover {
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.71);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.71);
    transition-duration: 0.25s;
}
