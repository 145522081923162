.headbar {
    background-color: black;
    text-align: center;

    position: fixed;
    width: 100%;
    z-index: 1;
}

.headbar img {
    width: 2.5rem;
    height: 3rem;
}