.error-box {
    text-align: center;
    padding: 0;
}

.error-message {
    color: var(--dark-red);
    margin: 0;
}

.dark-mode .error-message {
    color: var(--red);
}

.error-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1em;
    color: var(--dark-red);
}

.dark-mode .error-section {
    color: var(--red);
}

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    color: var(--dark-red);
}

.dark-mode .error-page {
    color: var(--red);
}
