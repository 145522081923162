.order-list {
    margin-top: 1rem;
}

.video-or-artist-list {
    padding: 0;
    padding-left: 0.5rem;
    list-style: none;
    overflow: auto;
    width: 95%;
    height: 60vh;
}

.video-entry {
    margin: 0.9rem;
    padding: 0.25rem;
    display: grid;
    grid-template-columns: .1fr .6fr 1fr;
    gap: 0.5rem;
    align-items: center;
    font-weight: bold;
}

.video-entry:hover {
    cursor: pointer;
    opacity: 0.5;
}

.video-entry p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-entry h4 {
    font-size: 1rem;
}

.video-thumbnail {
    width: 7rem;
    border: 1px solid black;
}

.artist-entry {
    margin: 1rem 0rem;
    display: grid;
    grid-template-columns: .1fr .2fr 1fr;
    gap: 0.5rem;
    align-items: center;
    gap: 1rem;
}

.artist-entry:hover {
    cursor: pointer;
    opacity: 0.5;
}

.artist-entry h4 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.artist-thumbnail {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid black;
}

@media only screen and (min-height: 800px) {
    .video-or-artist-list {
        height: 60vh;
    }
}

@media only screen and (min-height: 1000px) {
    .video-or-artist-list {
        height: 70vh;
    }

    .video-entry {
        font-size: 2rem;
    }

    .video-entry h4 {
        font-size: 2rem;
    }
    
    .video-thumbnail {
        width: 10rem;
    }

    .video-entry {
        font-size: 2rem;
    }

    .video-entry h4 {
        font-size: 2rem;
    }
    
    .video-thumbnail {
        width: 10rem;
    }

    .artist-entry {
        font-size: 2rem;
    }

    .artist-entry h4 {
        font-size: 2rem;
    }
    
    .artist-thumbnail {
        width: 4rem;
        height: 4rem;
    }
}

@media only screen and (min-height: 1450px) {
    .video-or-artist-list {
        height: 80vh;
    }
}

@media only screen and (min-height: 1800px) {
    .video-or-artist-list {
        height: 80vh;
    }
}

@media only screen and (min-height: 1080px) {
    .video-entry {
        grid-template-columns: .1fr .3fr 1fr;
    }
}

@media only screen and (min-width: 1920px) {
    .video-entry {
        grid-template-columns: .1fr .3fr 1fr;
    }
}