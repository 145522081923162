
/* Modal Styling */
.ReactModal__Body--open {
    overflow-y: hidden;
}

/* Modal Overlay */
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.user-auth-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background);
    z-index: 4;
}

/* Modal Content */
.user-auth-modal {
    background-color: var(--light-background);
    border-radius: 5px;
    font-size: 16px;
    max-height: 75%;
    min-width: 100px;
    max-width: 80vw;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    padding: 20px;

    /* Center the modal in the middle of the screen */
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) !important;
    -webkit-box-shadow: 0px 0px 8px -1px black;
    box-shadow: 0px 0px 8px -1px black;
    color: var(--dark-1);

    overflow-y: auto;
}

.dark-mode .user-auth-modal {
    background-color: var(--dark-1);
    color: var(--light-1);
}

.user-auth-modal h1 {
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
}

.user-auth-modal .close-btn {
    padding: 0;
}

/* Form Controls */
.form-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-controls > * {
    margin: 0.25em 0;
}

form p {
    color: inherit;
}

/* Form Content */
#login-form, #forgot-password-form, #register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    margin: auto;
    margin-bottom: 0;
}

/* Login Form */
.login-form__header h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
}

.login-form__header p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
}

.login-form__header {
    margin-top: 1em;
    margin-bottom: 2em;
}

/* Register Form */
.register-form__header h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
}

.register-form__header p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
}

.register-form__header {
    margin-top: 1em;
    margin-bottom: 2em;
    color: inherit;
}

.name-fields {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
}

.name-fields > :first-child {
    margin-right: 10px;
}

.name-fields input {
    margin-bottom: 0;
}

/* Forgot Password Form */
.forgot-password-form__header {
    color: inherit;
}

.forgot-password-form__header h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
    color: inherit;
}

.forgot-password-form__header p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
    color: inherit;
}

.forgot-password-form__header {
    margin-top: 1em;
    margin-bottom: 2em;
}

.form-header-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
}

.form-header-controls * {
    padding: 0;
}



/* Responsive Modala*/
@media screen and (max-width: 850px) {

    .user-auth-modal {
        min-width: 250px;
        max-width: 90vw;
    }

    .form-field-group {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .name-fields {
        display: flex;
        flex-flow: row wrap;
    }

    .name-fields > :first-child {
        margin-right: 0;
    }

}
