body {
    box-sizing: border-box;
}

.main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

/* Set the size of the box where all login elements will be */
.login-box {
    width: 50vh;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    padding: 1rem 0.75rem;

    display: flex;
    flex-direction: column;
    flex-basis: auto;

    background-color: #D2D2D2;
    box-shadow: 0px 0px 5px black;
}

.login-box a {
    text-decoration: none;
    color: black;
}

.login-box a:hover {
    color: #676666;
    text-decoration: underline;
}

.logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.50rem 0rem;
}

.logo-section h3 {
    font-style: italic;
}

.logo-section img {
    width: 7rem;
}

.login-data {
    display: flex;
    flex-direction: column;

    font-size: 1.25rem;

    padding: 0rem 3rem;
}

.login-data p {
    margin: 0rem 0.5rem;
}

.login-data input {
    background-color: transparent;
    border: 1.50px solid #676666;
    border-radius: 3px;
    margin: 0;
    padding: 0.50rem 0.25rem;
    width: 100%;
    font-size: 1rem;
}

.error-display {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
}

.error-display.active {
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 0rem;
    padding: 1rem 1rem;
    cursor: default;
    background-color: #ffdce0;
    border-radius: 5px;
    border: 1px solid gray;
}

.error-display p {
    color: #6a2327;
    font-size: calc(1rem);
}

.remove-error {
    visibility: hidden;
}

.remove-error.active {
    visibility: visible;
    cursor: default;
}

.remove-error:hover {
    cursor: pointer;
}

.login-data input[type='checkbox'] {
    width: 10%;
    margin-left: 0%;
}

.checkBox {
    margin: 1rem 0rem 0.5rem;
    font-size: 1rem;
}

.submit-section {
    margin-top: 0.5rem;
    display: flex;
    padding: 0rem 0rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.submit-section p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.submit-section button {
    background-color: #FAD42A;
    border: 1px solid black;
    border-radius: 3px;
    padding: 0.35rem 1rem;
    margin: 0;
    font-size: calc(1rem + 0px);
}

.submit-section button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px black;
}

.back-to-main-site {
    margin-top: 1rem;
}

@media only screen and (max-width: 1000px) {
    .login-box {
        
    }
}