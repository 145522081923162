.account-page {
    width: 100%;
    margin: auto;
    color: var(--dark-1);
}

.dark-mode .account-page {
    color: var(--light-1);
}

.account-page__content {
    width: 90vw;
    margin: auto;
    color: inherit;
}

.account-page__panels {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: center;
    margin: auto;
    color: inherit;
}

.account-page__header {
    display: flex;
}

.settings {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 1;
    color: inherit;
}



#email-group {
    margin-bottom: 0.25em;
}

#emailUpdates {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 0.5em;
}

#emailUpdates p {
    font-size: 0.85em;
    margin: 0;
}


.change-password__header h3, .user-fields__header h3, .delete-account__header h3 {
    margin-bottom: 0.25em;
}
.change-password__header p, .user-fields__header p, .delete-account__header p {
    margin-top: 0.25em;
    text-align: left;
    font-size: 13px;
}

.change-password__header, .user-fields__header, .delete-account__header {
    margin-bottom: 1em;
}

/* Liked Content */
.liked-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 1em;
    flex: 1;
    color: inherit;
}

.liked-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 20em;
    overflow-y: auto;
}

.liked-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    width: 90%;
}

.liked-section__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.artist-info, .video-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.artist-info:hover, .video-info:hover {
    opacity: 0.75;
    transition-duration: 0.25s;
}

.artist-info img {
    display: inline;
    width: 4em;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 1em;
}

.video-info img {
    display: inline;
    width: 125px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 1em;
}

.liked-item svg {
    color: var(--red);
    font-size: 1.5em;
}

.dark-mode .liked-item svg {
    color: var(--red-2);
}

.liked-item svg:hover {
    cursor: pointer;
    filter: brightness(0.8);
}

.no-liked-message {
    color: var(--dark-1);
}

.dark-mode .no-liked-message {
    color: var(--light-1);
}

.success-message {
    font-size: 13px;
    margin-top: 0;
    text-align: right;
}

.success-message > p {
    color: green;
}

.dark-mode .success-message > p {
    color: #00CC00;
}

/* Delete Account */
#delete-account-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#delete-account-form > button:first-child {
    margin-right: 1em;
}

.delete-account__header p {
    color: inherit;
}

.error-box {
    color: var(--red-2)
}

/* Smaller Screen Size */
@media screen and (max-width: 850px) {
    .liked-item {
        width: 100%;
    }

    #emailUpdates {
        justify-content: flex-start;

    }
}
