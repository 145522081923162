.bottom-bar {
    background-color: black;
    height: 40px;
    bottom: 0;
    width: 100%;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    z-index: 1;
    color: var(--gold);
    font-size: 14px;
}

.bottom-bar img {
    height: 20px;
    width: auto;

    margin: auto 10px;
}

.bottom-bar a {
    color: inherit;
    text-decoration: none;
    /* margin: auto 10px; */
}

.bottom-bar p {
    color: inherit;
    /* margin: auto 10px; */
    display: inline-block;
}

.bottom-bar__left {
    display: flex;
    align-items: center;
}

.bottom-bar__left > * {
    margin: auto 0.25em;
}

/* Responsive */
@media screen and (max-width: 850px) {

    .bottom-bar {
        height: 30px;

        font: 10px;
    }

    .bottom-bar img {
        margin: auto 5px;
    }

}
