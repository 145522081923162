.video-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    width: 100%;
    /* background-color: greenyellow; */
    grid-gap: 10px;
}

.video-results p {
    color: var(--dark-1);
}

.dark-mode .video-results p {
    color: var(--light-1);
}

.video-result {
    text-decoration: none;
    /* background-color: red; */
    margin-bottom: 1em;
    color: var(--dark-1);
}

.video-result:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.dark-mode .video-result {
    color: var(--light-1);
}

.video-result > img {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 5px;;
}


.details__title {
    font-size: 1em;
    font-weight: 500;
    margin-top: 0.25em;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
}

/* Responsive */
@media screen and (max-width: 850px) {
    .video-results {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media screen and (max-width: 700px) {
    .video-results {
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    }
}

@media screen and (max-width: 500px) {
    .video-results {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}
