.user-page-admin-section {
    margin-top: 1rem;
}

.admin-titles-and-buttons {
    display: flex;
    align-items: center;
    gap: 33%;
}

.total-admin-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.italics {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

#total-text-font-size {
    font-size: 0.75rem;
}

.list-of-admins-container {
    overflow: auto;
    height: 20vh;
    width: 115vh;
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-of-admins-admin-entry {
    display: grid;
    grid-template-columns: 0.75fr 1fr 0.5fr;
    align-items: center;
    margin: 0.75rem 0.25rem;
}

.list-of-admins-admin-entry:first-child {
    margin: 0rem 0.25rem;
}

.list-entry__actions-container {
    display: flex;
    gap: 0.75rem;
}

.user-page-user-section {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
}

.user-page-user-section__header {
    display: flex;
    font-size: calc(1rem + 3px);
    align-items: center;
    gap: 0.75rem;
}

.list-of-users-container {
    overflow: auto;
    height: 33vh;
    width: 95%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-of-users-container-entry {
    display: grid;
    grid-template-columns: 0.75fr 1fr 1fr 0.5fr;
    align-items: center;
    margin: 0.5rem 0rem;
}

/* .list-of-users-email-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-right: 0.5rem;
} */

.list-of-users-email-container {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.7rem;
}

.user-page-user-section__filter {
    margin-top: 1.5rem;
    padding: 0.25rem 0.25rem;
}

.filter-list {
    margin: 1rem 0rem;
    display: block;
}

.filter-list label {
    display: block;
    margin-top: 0.25rem;
}

.filter-list input {
    margin-right: 0.5rem;
}

.filter-list input:hover {
    cursor: pointer;
}

.add-admin-button {
    width: fit-content;
    max-width: 100%;
    height: 1.5rem;
    text-decoration: none;
    color: black;
    background-color: #FAD42A;
    margin: 0%;
    margin-right: 7rem;
    padding: 0.15rem 1rem;
    border-radius: 5px;
    border-width: 0px;
    font-weight: bold;
}

.add-admin-button:hover {
    cursor: pointer;
    box-shadow: 0 0 7px black;
}

.pages-container {
    margin-right: 1rem;

    border: 1px solid red;
}

.pages-container button {
    color: white;
    background-color: black;
    margin: 0rem 1rem;
}

@media only screen and (max-height: 600px) {
    .user-page-user-section__filter {
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 1000px) {
    .user-page-user-section {
        margin-top: 0.75rem;
        display: grid;
        grid-template-columns: 0.9fr 0.1fr;
    }

    .list-of-users-container-entry {
        font-size: 0.75rem;
    }

    .user-page-user-section__filter {
        padding: 0;
    }
    
    .filter-list label {
        font-size: 0.75rem;
    }
    
    .filter-list p {
        font-size: 1rem;
    }
} 

@media only screen and (min-height: 1080px) {
    .user-page-user-section {
        margin-top: 4rem;
    }

    .user-page-user-section__filter span {
        font-size: 1.5rem;
    }

    .bold {
        font-size: 1.75rem !important;
    }

    .checkBox {
        font-size: 1.5rem;
    }

    input[type=radio] {
        width: 10%;
        height: 1.5em;
    }
}

@media only screen and (min-width: 1920px) {
    .user-page-user-section {
        margin-top: 4rem;
    }

    .user-page-user-section__filter span {
        font-size: 1.5rem;
    }

    .bold {
        font-size: 1.75rem !important;
    }

    .checkBox {
        font-size: 1.5rem;
    }

    input[type=radio] {
        width: 10%;
        height: 1.5em;
    }
}