.popup-background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.popup-container.layout {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    min-width: fit-content;
    width: 40%;
    height: 70vh;
    translate: 76% 15% 0px;
}

.remove-popup-container {
    margin: 0;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
}

.remove-popup-button {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    color: black;
}

.remove-popup-button:hover {
    cursor: pointer;
}

.layout-form-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem;
    align-items: center;
    align-content: center;
    text-align: center;
    line-height: 1.5rem;
    min-width: fit-content;
    height: 80%;
}

.autocomplete-container {
    margin: 3rem 0rem;
}

@media only screen and (max-width: 1000px) {
    .popup-container {
        translate: 10% 10% 0px;
    }

    .popup-background {
        width: 110vh;
    }
}

@media only screen and (min-height: 1080px) {
    .popup-container.layout {
        position: inherit;
        min-width: fit-content;
        width: 40%;
        height: 45vh;
        translate: 76% 50% 0px;
    }
}

@media only screen and (min-width: 1920px) {
    .popup-container.layout {
        position: inherit;
        min-width: fit-content;
        width: 40%;
        height: 45vh;
        translate: 76% 50% 0px;
    }
}