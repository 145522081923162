
.btn {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;

    padding: 10px 20px;
    font-weight: 600;
    font-size: 1rem;
}

.btn:disabled {
    cursor: not-allowed;
    filter: brightness(0.8);
}

.btn:disabled:hover {
    cursor: not-allowed;
    filter: brightness(0.8);
    box-shadow: none;
    --webkit-box-shadow: none;
}

.btn:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 1px black;
    box-shadow: 0px 0px 3px 1px black;
    transition-duration: 0.25s;
    filter: brightness(1.1);
}



.btn__text, .btn p {
    margin: 0 5px;
    color: inherit;
    text-decoration: none;
}

.btn__primary {
    background-color: var(--dark-1);
    color: var(--light-1);
}

.dark-mode .btn__primary {
    background-color: var(--gray-1);
    color: var(--dark-1);
}

.btn__secondary {
    background-color: transparent;
    border: 3px solid var(--dark-1);
    color: var(--dark-1);
}

.dark-mode .btn__secondary {
    border-color: var(--gray-1);
    color: var(--gray-1);
}

.btn__no-style {
    background-color: transparent;
    border: none;
    color: var(--dark-1);
    font-weight: 400;
}

.dark-mode .btn__no-style {
    color: var(--light-1);
}

.btn__no-style:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
}

.btn__danger {
    background-color: var(--red);
    color: var(--light-1);
}

.dark-mode .btn__danger {
    background-color: var(--red-2);
    color: var(--light-1);
}

.btn__liked {
    color: var(--red) !important;
}

.btn img, .btn svg {
    width: 20px;
}

a.btn-link {
    color: inherit;
    text-decoration: none;
}

.btn-link > button {
    padding: 0;
}
