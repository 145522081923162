.account-container {
    margin-right: 3rem;
}

.boxed-text-container {
    display: flex;
    justify-content: space-between;
}

.form-container {
    margin: 1rem 0rem;
}

.input-container {
    margin: 1.5rem 0rem;
    display: grid;
    grid-template-columns: 0.15fr 0.85fr;
}

.form-text-input {
    width: 30%;
    padding: 0.25rem 0.25rem;
}

.bigger-font {
    font-size: 1.05rem;
}

.password-section-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


/* Video Forms */
.header-section-with-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 5rem;
}

.gap-for-buttons {
    display: flex;
    gap: 1rem;
}

.new-or-edit-video-form-container {
    margin-top: 1rem;
    margin-right: 3rem;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
}

.video-info-and-inputs {
    margin-right: 1rem;
}

.find-video-container {
    margin-bottom: 1.5rem;
}

.find-video-container h3 {
    padding: 0;
    margin: 0;
}

.youtube-link-input {
    margin: 0.5rem 0rem;
    display: flex;
    gap: 2rem;
}

.textarea-tag-input {
    margin-top: 0.25rem;
    width: 90%;
    height: 20vh;
    resize: none;
}

.tag-input {
    margin-top: 0.25rem;
    padding: 0.25rem 0.25rem;
    width: 100%;
    background-color: transparent;
    border-width: 1px;
    border-radius: 5px;
}

.privacy-setting-container {
    display: flex;
    gap: 2rem;
    align-items: baseline;
    margin: 1rem 0rem;
}

input[type=radio]:hover {
    cursor: pointer;
}

.smaller-font {
    font-size: 0.9rem !important;
}

.tags-list {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-basis: 100%;
    flex-wrap: wrap;
}

.tag-remove:hover {
    cursor: pointer;
    box-shadow: 0 0 5px black;
    text-decoration: line-through;
}

/* .tag-remove:hover::after {
    content: '\00d7';
} */

.form-error.active {
    height: 5%;
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 0rem;
    padding: 1rem 1rem;
    cursor: default;
    border-radius: 5px;
    border: 1px solid gray;
}

.form-error.active.red {
    background-color: #ffdce0;
}

.form-error.active.green {
    background-color: #D1ECC1;
}

.form-error.active.center {
    margin: 1rem 1.5rem;
}

.form-error p {
    color: #6a2327;
    font-size: calc(1rem);
}

.remove-error {
    visibility: hidden;
}

.remove-error.active {
    visibility: visible;
    cursor: default;
}

.remove-error.active:hover {
    cursor: pointer;
}

.confirmation-message {
    background-color: #D1ECC1;
    border-radius: 5px;
    width: 42%;
    padding: 1rem 1rem;
    align-items: center;
    text-align: center;
}

.layout-container {
    margin: 0;
    padding: 0;
    list-style: none;
}

.single-layout {
    margin: 1rem 1rem;
    margin-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 10%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid black;
}

.single-layout-inner {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.single-layout:active {
    transition-delay: 150ms;
    padding: 1rem;
}

/* Admin/User Form */

.new-admin-form-container {
    margin-top: 1rem;
    margin-right: 3rem;
}

.new-admin-form-input-container {
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    margin: 1.5rem 0rem;
}

.new-admin-form-input-container p {
    font-size: 1.2rem;
    font-weight: bold;
}

.new-admin-form-input-container input {
    border-radius: 5px;
    border-width: 1px;
    padding: 0.25rem 0.25rem;
    width: 30%;
}

.admin-form-err.active {
    height: 5%;
    width: 45%;
    display: grid;
    grid-template-columns: 1.9fr 0.1fr;
    margin: 1rem 0rem;
    padding: 0.5rem 1rem;
    cursor: default;
    border-radius: 5px;
    border: 1px solid gray;
}

.admin-form-err.active.red {
    background-color: #ffdce0;
}

.admin-form-err.active.green {
    background-color: #D1ECC1;
}

/* Artist Form Styling */

.artist-form-container {
    margin-top: 0rem;
}

.artist-form-upper-half {
    margin-top: 0.25rem;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 45vh;
}

.artist-form-lower-half {
    margin-top: 0.25rem;
}

.artist-form-picture-container {
    padding-top: 0.25rem;
    padding-left: 00.25rem;
    display: flex;
    flex-direction: column;
    height: 98%;
    overflow: hidden;
}

.quick-action.center {
    display: flex;
    align-items: center;
}

.artist-image-container {
    margin-top: 1rem;
    flex-grow: 1;
    background-color: #c4c4c4;
    border: 1px solid gray;
}

.message-in-image-container {
    font-style: italic;
    translate: 45% 500% 0;
    text-align: center;
    position: absolute;
}

.artist-image {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
}

.artist-form-info {
    margin-right: 2rem;
    margin-top: 2.75rem;
}

.artist-input-container {
    margin: 0rem 1rem;
    margin-bottom: 2rem;
}

.artist-name-input {
    width: 100%;
    padding: 0.25rem 0.25rem;
}

.artist-description-input {
    resize: none;
    width: 100%;
    height: 20vh;
}

.admin-form-button-section {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
}

.artist-form-button-section {
    margin: 0;
    display: flex;
    gap: 2rem;
}

/* Forgot Pssw */

.recovery-background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.recovery-container {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    width: 35%;
    height: 55vh;
    translate: 93% 40% 0px;
}

.recovery-code-container {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    width: 35%;
    height: 63vh;
    translate: 93% 30% 0px;
}

.change-pssw-container {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    width: 35%;
    height: 63vh;
    translate: 93% 30% 0px;
}

.forgot-pssw-popup {
    text-align: center;
}

.email-container {
    text-align: left;
    margin-top: 1.5rem;
}

.pssw-container {
    text-align: left;
    margin-top: 2.5rem;
}

.email-text {
    margin: 0.5rem 0rem;
    margin-left: 3rem;
    font-size: 1.5rem;
}

.recovery-section {
    margin-top: 2rem;
    text-align: center;
}

.recovery-section p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.recovery-section button {
    color: white;
    background-color: black;
    font-weight: bold;
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    font-size: calc(1rem + 0px);
}

.recovery-section button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px black;
}

@media only screen and (max-width: 1000px) {
    .artist-form-upper-half {
        grid-template-columns: 1fr 1fr;
    }

    .message-in-image-container {
        translate: 30% 500% 0;
    }
}

@media only screen and (max-width: 1024px) {
    .artist-image-container {
        height: 0vh;
    }

    .artist-form-upper-half {
        grid-template-columns: 0.8fr 1.2fr;
        height: 45vh;
    }
}

@media only screen and (min-width: 1800px) {
    .artist-image-container {
        width: 20vw;
        height: 0vh;
    }
}

@media only screen and (min-width: 1900px) {
    .artist-image-container {
        width: 15vw;
        height: 0vh;
    }
}

@media only screen and (min-width: 2560px) {
    .artist-image-container {
        width: 12vw;
        height: 0vh;
    }
}

@media only screen and (max-height: 750px) {
    .artist-form-upper-half {
        margin-top: 0rem;
        grid-template-columns: 0.5fr 1.5fr;
        height: 40vh;
    }
    
    .artist-form-lower-half {
        margin-top: 0.5rem;
    }

    .artist-form-picture-container {
        height: 70%;
    }
    
    .artist-image-container {
        width: 10vw;
    }
    
    .artist-form-info {
        margin-right: 2rem;
        margin-top: 0rem;
    }
}

@media only screen and (max-height: 650px) {
    .new-or-edit-video-form-container {
        margin-top: 0rem;
    }

    .find-video-container {
        margin-bottom: 0.5rem;
    }
}

@media only screen and (min-height: 1080px) {
    .artist-form-upper-half {
        grid-template-columns: 1fr 1.5fr;
        height: 50vh;
    }

    .artist-image-container {
        width: 90%;
    }

    .artist-form-lower-half {
        margin-top: 1.5rem;
    }

    input[type=radio] {
        width: 2%;
        height: 2em;
    }

    .privacy-setting-container p {
        font-size: 2rem;
    }

    .smaller-font {
        font-size: 1.5rem !important;
    }

    .artist-form-button-section {
        margin-top: 2rem;
    }

    .quick-action-button.form {
        font-size: 2rem;
    }

    .form-error.active {
        height: fit-content;
        width: fit-content;
        
        margin: 1rem 0rem;
        padding: 1rem 1rem;
        border-radius: 5px;
    }

    .form-error p {
        font-size: 1.5rem;
    }

    .artist-name-input {
        font-size: 1.5rem;
    }

    .artist-description-input {
        font-size: 1.25rem;
    }

    .find-video-container h3 {
        font-size: 2rem;
    }

    .new-or-edit-video-form-container {
        margin-top: 2.5rem;
    }

    .find-video-container {
        font-size: 2.5rem;
    }

    .gap-for-buttons {
        margin-top: 2rem;
        gap: 2.5rem;
    }

    .quick-action-button.bigger-font {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1920px) {
    .artist-form-upper-half {
        grid-template-columns: 1fr 1.5fr;
        height: 50vh;
    }

    .artist-image-container {
        width: 90%;
    }

    .artist-form-lower-half {
        margin-top: 1.5rem;
    }

    input[type=radio] {
        width: 2%;
        height: 2em;
    }

    .privacy-setting-container p {
        font-size: 2rem;
    }

    .smaller-font {
        font-size: 1.5rem !important;
    }

    .artist-form-button-section {
        margin-top: 2rem;
    }

    .quick-action-button.form {
        font-size: 2rem;
    }

    .form-error.active {
        height: fit-content;
        width: fit-content;
        
        margin: 1rem 0rem;
        padding: 1rem 1rem;
        border-radius: 5px;
    }

    .form-error p {
        font-size: 1.5rem;
    }

    .artist-name-input {
        font-size: 1.5rem;
    }

    .artist-description-input {
        font-size: 1.25rem;
    }

    .find-video-container h3 {
        font-size: 2rem;
    }

    .new-or-edit-video-form-container {
        margin-top: 2.5rem;
    }

    .find-video-container {
        font-size: 2.5rem;
    }

    .gap-for-buttons {
        margin-top: 2rem;
        gap: 2.5rem;
    }

    .quick-action-button.bigger-font {
        font-size: 1.5rem;
    }
}