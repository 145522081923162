.single-artist-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1em;
    color: var(--dark-1);
}

.dark-mode .single-artist-page {
    color: var(--light-1);
}

.single-artist-page__body {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 2160px;
}

/* Artist Section */
.single-artist-page__artist {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
}

/* Image */
.single-artist-page__artist-image  {
    flex: 1.25;
    aspect-ratio: 1/1;
}

#artist-image {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}

/* Details */
.single-artist-page__artist-info {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 3%;
}

/* Top Row */
.artist-info__top-row-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
/* Name */
.top-row-details__artist-name {
    background-color: var(--gray-2);
    font-size: 2.5em;
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 5px;
    width: fit-content;
    color: inherit;
}

.dark-mode .top-row-details__artist-name {
    background-color: var(--dark-2);
}

/* Interaction Buttons */
.top-row-details__interaction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 1em;
}

/* Biography */
.single-artist-page__artist-biography {
    margin-top: 1.5em;
    font-size: 1.025em;
}

/* Videos Section */
.related-videos {
    width: 100%;
}

.related-videos__header {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 1em;

}

.related-videos__header > * {
    margin: 0px;
}

/* Ticket Section */
.ticket-purchase {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ticket-purchase__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    height: min-content;
}

.ticket-purchase__header > h3 {
    line-height: 1em;
}

.ticket-purchase__header input {
    flex: 1;
    background: none;
    border: none;
    color: saddlebrown;
    border-bottom: 1px solid saddlebrown;
    font-size: inherit;
    width: fit-content;

    margin-left: 15px;

    text-align: center;

    height: fit-content;
}

.ticket-purchase__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#ticketmaster-logo {
    height: 1em;
    margin-left: 10px;
}

.ticket-purchase__body {
    min-height: 300px;
    /* background-color: red; */
    display: flex;
    width: 100%;
    height: 100%;
}

.ticket-purchase__body__events {
    /* background-color: purple; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

/* Individual Event Result */
.ticket-result {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ticket-result a {
    text-decoration: none;
}

.ticket-result > div, .ticket-result > a {
    margin-left: 10px;
    margin-right: 10px;
}

.ticket-result__date {
    font-weight: bold;
}

.ticket-result__city {
    margin-right: 25px;
}

/* Responsive */
@media screen and (max-width: 850px) {

    .single-artist-page__artist {
        flex-direction: column;
        align-items: center;
    }

    .single-artist-page__artist-info {
        margin-left: 0;
    }

    .single-artist-page__artist-biography {
        margin-top: 1em;
    }

    .single-artist-page__artist-image  {
        flex: 1;
        aspect-ratio: 1/1;
        max-width: 75vw;
    }

    .artist-info__top-row-details {
        flex-direction: column;
        margin-top: 1em;
    }

    .top-row-details__artist-name {
        text-align: center;
        font-size: 2em;
    }

    .ticket-purchase__header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .ticket-purchase__header > h3 {
        margin-bottom: 10px;
    }

    .ticket-purchase__header input {
        margin-left: 0;
    }

    .ticket-purchase__logo {
        margin-top: 10px;
    }

    .ticket-purchase__body {
        flex-direction: column;
        align-items: center;
    }

    .ticket-purchase__body__events {
        align-items: center;
    }

    .ticket-result {
        flex-direction: row;
        align-items: space-between;
        width: 100%;
    }

    .ticket-result > div, .ticket-result > a {
        margin-left: 0.5em;
    }

    .ticket-result__city {
        margin-right: 0;
    }


}
