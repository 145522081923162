.video-dashboard-header-container {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    align-items: center;
    margin-right: 3rem;
}

.video-dashboard-header-button-container {
    display: flex;
    gap: 1rem;
}

.video-dashboard-upper-half-container {
    margin: 0.5rem 0rem;
    margin-right: 3rem;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    gap: 1.5rem;
}

.video-dashboard-lower-half-container {
    margin: 0.5rem 0rem;
    margin-right: 3rem;
}

.italic-text {
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: 1rem !important;
}

.layout-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
}

.transparent-button {
    background-color: transparent;
    border-width: 0;
}

.transparent-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.tag-list-container {
    margin-top: 0.5rem;
    border: 1px solid #83827C;
}

.tag-list {
    margin-top: 0.25rem;
    width: 93%;
    height: 20vh;
    padding: 0.5rem 0.5rem;
    overflow: auto;
}

.featured-video-section {
    margin: 0rem 0.25rem;
}

.feature-and-link-video-container {
    padding: 1rem 0rem;
}

.checkbox-container {
    margin: 0.5rem 0rem;
}

.video-search-and-new-video-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 5rem;
}

.new-video-form {
    color: black;
    background-color: #FAD42A;
    min-width: fit-content;
    margin: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    text-decoration: none;

    font-weight: bold;
    border-width: 0;
}

.new-video-form:hover {
    cursor: pointer;
    box-shadow: 0 0 7px black;
}

.form-error.active {
    height: 4%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0rem;
    padding: 0.5rem 0.5rem;
    cursor: default;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: black;
}

.form-error.active.red {
    background-color: #ffdce0;
}

.form-error.active.green {
    background-color: #D1ECC1;
}

@media only screen and (max-width: 1000px) {
    .video-dashboard-header-container {
        grid-template-columns: 0.8fr 1.2fr;
    }
}

@media only screen and (max-height: 750px) {
    .video-dashboard-header-container {
        margin: 0;
    }

    .video-dashboard-upper-half-container {
        margin: 0;
    }

    .italic-text {
        padding-top: 0rem;
    }

    .tag-list {
        margin-top: 0rem;
        height: 15vh;
        padding: 0;
    }
    
    .feature-and-link-video-container {
        padding: 0rem 0rem;
    }
    
    .checkbox-container {
        margin: 0rem 0rem;
    }

    .video-dashboard-lower-half-container {
        margin: 0.25rem 0rem;
    }
}

@media only screen and (min-height: 1080px) {
    .tag-list {
        margin: 1rem 0rem;
        height: 20vh;
    }

    .new-video-form {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1920px) {
    .tag-list {
        margin: 1rem 0rem;
        height: 20vh;
    }

    .new-video-form {
        font-size: 1.5rem;
    }
}