.video-category {
    width: 100%;
    height: 100%;
}

.video-category__title {
    font-size: 1.1em;
    margin-bottom: 0.5em;
}

.dark-mode .video-category__title {
    color: var(--light-1)
}

.video-category__videos {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: auto;
    height: 100%;
}

/* Container */
.videos__video-thumbnail {
    margin-right: 10px;
    position: relative;
    text-decoration: none;
    color: var(--dark-1);
    max-width: 265px;
}

.videos__video-thumbnail:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.dark-mode .videos__video-thumbnail {
    color: var(--light-1);
}

.videos__video-thumbnail img {
    height: 150px;
    width: fit-content;
    aspect-ratio: 16/9;
    display: block;
    border-radius: 5px;
}

.videos__video-thumbnail p {
    margin-top: 0.5em;
    font-weight: 500;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
}


.video-category__view-all {
    background-color: var(--dark-1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    font-size: 1.15em;
    color: var(--light-1);
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
}

.video-category__view-all:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 1px black;
    box-shadow: 0px 0px 3px 1px black;
    transition-duration: 0.25s;
    filter: brightness(1.1);
}

.dark-mode .video-category__view-all {
    background-color: var(--gray-1);
    color: var(--dark-1);
}

.video-category__view-all p {
    color: var(--light-1);
    margin: auto 25px;
    font-weight: 500;
}

.dark-mode .video-category__view-all p {
    color: var(--dark-1);
}
