.artist-page-header {
    display: flex;
    justify-content: space-between;
    margin-right: 5rem;
    align-items: center;
}

.artis-page-header-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    flex-wrap: wrap;
    width: 80%;
}

.artis-page-header-buttons p {
    font-size: 1rem;
}

.featured-artist-section {
    margin-top: 1rem;
}

.featured-artist-section-top {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.featured-artist-image {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}

.artist-portraits-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
    display: flex;
    gap: 5rem;
    overflow-x: auto;
}

.artist-picture {
    display: inline-block;
    text-align: center;
}

.artist-picture:hover {
    cursor: pointer;
    text-decoration: underline;
}

.artist-portraits-section img {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
}

.artist-portraits-section img:hover {
    opacity: 0.5;
}

.add-artist {
    color: gray;
}

.add-artist:hover {
    cursor: pointer;
    color: black;
}

.artist-search-container {
    margin-top: 0.5rem;
}

.artist-search-and-add-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3rem;
}

.artist-list-container {
    overflow: auto;
    width: 96%;
    height: 26vh;
    list-style: none;
    margin: 0;
    padding: 0;
}

.artist-info-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0.25rem 0rem;
    margin-bottom: 0.5rem;
    align-items: center;
}

.artist-info-container img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
}

@media only screen and (max-height: 650px) {
    .artist-portraits-section img {
        width: 5rem;
        height: 5rem;
    }
}

@media only screen and (max-height: 650px) {
    .artist-portraits-section img {
        width: 3rem;
        height: 3rem;
    }
}

@media only screen and (min-height: 1080px) {
    .artist-portraits-section {
        gap: 5rem;
        margin-bottom: 3rem;
    }

    .artist-portraits-section img {
        width: 15rem;
        height: 15rem;
    }

    .artist-list-container {
        height: 30vh;
    }
}

@media only screen and (min-width: 1920px) {
    .artist-portraits-section {
        gap: 5rem;
        margin-bottom: 3rem;
    }

    .artist-portraits-section img {
        width: 15rem;
        height: 15rem;
    }

    .artist-list-container {
        height: 30vh;
    }
}