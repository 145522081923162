/* Featured Video */
.featured-video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: yellow; */
    margin: auto;
    width: 90%;
    padding: 5%;
}

.featured-video__video {
    flex: 2;
    /* background-color: orange; */
}

.featured-video__video iframe {
    aspect-ratio: 16/9;
    width: 100%;
}

.featured-video__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.default-details {
    display: flex;
    width: 100%;
    text-align: right;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
    margin-bottom: 1em;
}

.featured-video__buttons button:first-child {
    margin-right: 0.5em;
}

/* Detail Text */
#this-week {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    color: #676666;
    margin: 10px 0;
    font-style: italic;
}

.dark-mode #this-week {
    color: var(--light-1);
}

#nashville-insider {
    font-family: 'Old Standard TT', serif;
    font-size: 42px;
    line-height: 0.8em;
    margin: 10px 0;
    letter-spacing: 0.25px;
    word-wrap: break-word;
}

.dark-mode #nashville-insider {
    color: var(--brown);
}

#subheading {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    /* margin: 10px 0 20px 0; */
    margin-top: 0;
}

.dark-mode #subheading {
    color: var(--light-1);
}

.featured-video__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 1em;
}

.featured-video__buttons a {
    text-decoration: none;
}
.featured-video .btn {
   height: 3em;
}

.featured-video__buttons:first-child {
    margin-right: 10px;
}

.details-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background);
    z-index: 4;
}

/* Modal Content */
.details-modal {
    background-color: var(--light-background);
    border-radius: 5px;
    font-size: 16px;
    min-width: 30vw;
    min-height: fit-content;
    margin: auto;
    justify-content: center;
    align-items: center;
    /* font-family: 'Roboto', sans-serif; */
    padding: 20px;

    /* Center the modal in the middle of the screen */
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) !important;
    -webkit-box-shadow: 0px 0px 8px -1px #000000;
    box-shadow: 0px 0px 8px -1px #000000;
    color: var(--dark-1);
}

.dark-mode .details-modal {
    background-color: var(--dark-1);
    color: var(--light-1);
}

.details-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.details-modal__header > .btn:last-child {
    padding: 0;
}

.dark-mode .details-modal__description p {
    color: var(--light-1);
}

#close-btn {
    padding: 0;
}

@media screen and (max-width: 850px) {

    .featured-video {
        flex-direction: column;
        align-items: flex-start;
    }

    .featured-video__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .featured-video__video {
        width: 100%;
    }

    .featured-video__details {
        width: 100%;
        text-align: left;
        margin-top: 2em;
        margin-left: 0;
    }

    .default-details {
        text-align: left;
        margin-left: 0;
    }

    .featured-video__buttons {
        justify-content: flex-start;
        margin-left: 0;
    }

    .details-modal {
        min-width: 80vw;
    }

}
