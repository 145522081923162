.sidebar-top span{
    margin: 0;
    padding: 0;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
}

.admin-navbar {
    padding-top: 4rem;
    background-color: #3D3D3D;
    color: white;
    width: 30vh;
    height: 100vh;
    line-height: 0.5rem;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 1000ms;
}

.admin-navbar.active {
    left: 0%;
    transition: 400ms;
}

.admin-navbar h2 {
    font-size: 1.6rem;
    padding: 0rem 2rem;
}

.admin-navbar a {
    margin: 0;
    padding: 0;
    color: white;
    text-decoration: none;
}

.admin-navbar > a {
    font-size: 1.3rem;
    padding: 0rem 2rem;
} 

.admin-navbar a:hover {
    cursor: pointer;
    text-decoration: underline;
    text-shadow: 0 0 5px black;
}

.admin-navbar-menu {
    margin-top: 2rem;
    list-style: none;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.admin-navbar-menu-list-item {
    display: flex;
    align-items: center;
    margin: 1.5rem 0rem;
    font-size: calc(1rem + 5px);
    gap: 1rem;
    padding: 0.75rem 0rem;
    padding-left: 1.5rem;
}

.admin-navbar-menu-list-item.current {
    background-color: #FAD42A;
    color: black;
}

#logout {
    /* color: #FF4F4F; */
    /* color: #fb9383; */
    color: #fb8876;
}

@media only screen and (max-width: 1000px) {
    .admin-navbar {
        width: 15vh;
    }

    .admin-navbar h2 {
        font-size: 1rem;
        padding: 0rem 0rem;
    }

    .admin-navbar > a {
        font-size: 0.75rem;
        padding: 0rem 0rem;
    }

    .admin-navbar-menu {
        padding: 0rem;
    }

    .admin-navbar-menu-list-item {
        margin: 1.5rem 0rem;
        font-size: calc(1rem + 5px);
        gap: 0rem;
        padding: 0rem 0rem;
    }

    .admin-navbar-menu-list-item {
        font-size: 0.90rem;
    }
}

@media only screen and (max-width: 650px) {
    .admin-navbar {
        width: 3vh;
        height: 200vh;
    }

    .admin-navbar h2 {
        display: none;
    }
    
    .admin-navbar-menu span {
        display: none;
    }

    .view-account-link {
        display: none;
    }
}

@media only screen and (max-height: 730px) {
    .view-account-link {
        font-size: 1rem !important;
    }

    .admin-navbar-menu {
        padding: 0;
        margin: 0;
    }

    .admin-navbar-menu span {
        font-size: 1rem;
    }
}

@media only screen and (max-height: 680px) {
    .admin-navbar-menu-list-item {
        margin: 0;
        margin-top: 1rem;
    }

    .view-account-link {
        font-size: 1rem !important;
    }

    .admin-navbar-menu {
        padding: 0;
        margin: 0;
    }

    .admin-navbar-menu span {
        font-size: 1rem;
    }
}

@media only screen and (max-height: 540px) {
    .admin-navbar h2 {
        font-size: .9rem;
    }

    .view-account-link {
        font-size: 0.7rem !important;
    }

    .admin-navbar-menu span {
        font-size: 1rem;
    }
}

@media only screen and (min-height: 1080px) {
    .admin-navbar h2 {
        font-size: 2rem;
    }
    
    .admin-navbar a {
        font-size: 1.5rem;
    }
    
    .admin-navbar-menu-list-item {
        margin: 2.5rem 0rem;
        padding: 1.5rem 0rem;
        padding-left: 1.5rem;
        font-size: calc(2rem + 0px);
    }
}

@media only screen and (min-width: 1920px) {
    .admin-navbar h2 {
        font-size: 2rem;
    }
    
    .admin-navbar a {
        font-size: 1.5rem;
    }
    
    .admin-navbar-menu-list-item {
        margin: 2.5rem 0rem;
        padding: 1.5rem 0rem;
        padding-left: 1.5rem;
        font-size: calc(2rem + 0px);
    }
}