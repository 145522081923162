.dashboard-err {
    height: fit-content;
    width: 70%;
    display: grid;
    grid-template-columns: 1.9fr 0.1fr;
    align-items: center;
    margin: 1rem 0rem;
    padding: 0.5rem 1rem;
    cursor: default;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 100%;
}

.red {
    background-color: #ffdce0;
}

.green {
    background-color: #D1ECC1;
}

@media only screen and (min-height: 800px) {
    .dashboard-err {
        width: 70%;
        font-size: 150%;
        border-radius: 10px;
    }
}