.checkbox-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    font-weight: 500;

    width: fit-content;

    color: var(--dark-1);
}

.dark-mode .checkbox-group {
    color: var(--light-1);
}

.checkbox-group:hover {
    cursor: pointer;
}

.checkbox-group svg {
    margin-right: 0.5em;
}

.checkbox-group-text {
    color: inherit;
}
