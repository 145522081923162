.input-group {
    display: flex;
    flex-direction: column;

    flex: 1;
    max-width: 50%;

    color: inherit;
}

.input-group .error {
    color: var(--red);
    font-size: 13px;
    margin-top: 0;
    text-align: right;
}

.dark-mode .input-group .error {
    color: var(--red);
}

.input-group > .error-state {
    border-color: var(--red);
}

.form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1em;
    width: 100%;
}

.form-group input {
    border-radius: 5px;
    border: 1px solid var(--gray-1);
    padding: 0.5em;
    font-size: 15px;
}

.dark-mode .form-group input {
    background-color: var(--dark-2);
    color: var(--light-1);
    border-color: var(--gray-1);
}

.form-group > label {
    flex: 1;
}

.form-group.compact {
    flex-direction: column;
}

.form-group.compact > .input-group {
    max-width: 100%;
    width: 100%;
    margin-top: 0.25em;
}
