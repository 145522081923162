.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #C06B2A;
}

p {
    color: var(--dark-1);
}


.user-page {
    position: absolute;
    top: 50px;
    padding-bottom: 50px;
}

:root {
    --dark-1: #1a1a1a;
    --dark-2: #333333;
    --light-1: #f9f9f9;
    --brown: #c56e2b;
    --red: #EA4D4D;
    --red-2: #D21919;
    --dark-red: #8D2B20;
    --gray-1: #bdbdbd;
    --modal-background: #00000077;
    --light-background: #edece4;
    --gray-2: #c3c4bb;
    --gold: #FAD42A;
}
