.video-list-container {
    margin: 0;
    margin-right: 11rem;
    padding: 0;
    height: 20vh;
    overflow: auto;
}

.video-list-grid {
    display: grid;
    grid-template-columns: 0.9fr 0.6fr 0.5fr;
}

.empty-query {
    font-style: italic;
    padding-top: 5rem;
    align-items: center;
    text-align: center;
}

.video-find-container {
    list-style: none;
    margin: 0.25rem 1rem;
    padding: 0;
    display: grid;
    grid-template-columns: 0.9fr 0.6fr 0.5fr;
    align-items: center;
}

.video-find-container img {
    width: 6rem;
}

.thumbnail-container {
    display: flex;
    align-items: center;
}

.thumbnail-container p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnail-titles {
    margin-left: 1rem;
}

.buttons-container {
    display: flex;
    margin-left: 0.5rem;
    gap: 1rem;
}

.green-font {
    color: #579930;
}

.red-font {
    color: #e15c56;
}

@media only screen and (min-height: 1080px) {
    .video-list-container {
        height: 30vh;
    }
}

@media only screen and (min-width: 1920px) {
    .video-list-container {
        height: 30vh;
    }
}