.search-results-page {
    width: 100%;
    margin: auto;
    color: var(--dark-1);
}

.dark-mode .search-results-page {
    color: var(--light-1);
}

.content {
    width: 90%;
    margin: auto;
}

.artist-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
}

.artist-results > p {
    color: var(--dark-1);
}

.dark-mode .artist-results > p {
    color: var(--light-1);
}

.artist-result {
    display: flex;
    flex: 20% 0 1;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    color: inherit;
    margin-right: 5em;
    margin-bottom: 2.5em;
    font-weight: 500;
}

.artist-result:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.artist-result > img {
    display: inline;
    width: 5em;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
}

.artist-result > p {
    color: inherit;
}

.query {
    margin-bottom: 0;
    padding-bottom: 0;
}

#result-count {
    margin: 0;
    padding: 0;
    color: var(--dark-1);
    font-style: italic;
    font-size: 0.9em;
}

.dark-mode #result-count {
    color: var(--light-1);
}
