.share-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background);
    z-index: 4;
}

/* Modal Content */
.share-modal {
    background-color: var(--light-background);
    border-radius: 5px;
    font-size: 16px;
    min-height: fit-content;
    margin: auto;
    justify-content: center;
    align-items: center;
    /* font-family: 'Roboto', sans-serif; */

    /* Center the modal in the middle of the screen */
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) !important;
    -webkit-box-shadow: 0px 0px 8px -1px #000000;
    box-shadow: 0px 0px 8px -1px #000000;
    color: var(--dark-1);

    /* Content Design */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.dark-mode .share-modal {
    background-color: var(--dark-1);
    color: var(--light-1);
}

.share-modal__content {
    margin: 15px;
}

.content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.content__header > .btn {
    padding: 0;
    margin-left: 2em;
}

.content__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.content__options > .col:first-child {
    margin-right: 3em;
}

.share-option {
    display: flex;
    /* flex-direction: row;
    justify-content: flex-start;
    align-items: center; */
    margin: 10px auto;
}

.share-option__icon img, .share-option__icon svg {
    width: 20px;
}

.share-modal h2 {
    margin: 0;
    padding: 0;
}

/* Smaller Screen Size */
@media screen and (max-width: 850px) {
    .share-modal {
        max-width: 90%;
        width: max-content;
    }
}
