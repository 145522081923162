.navbar {
    background-color: black;
    color: var(--gold);
    height: 50px;
    top: 0;
    width: 100%;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    font-size: 1em;
    font-weight: 600;
    z-index: 1;
}

.navbar p {
    color: var(--gold);
}


#navbar-logo {
    height: 75%;
    max-height: 50px;
    margin-left: 1em;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-container input {
    background-color: black;
    color: var(--gold);
    font-size: 1em;

    /* Border */

    border-color: var(--gold);
    border-style:solid;

    border-top: 0;
    border-left: 0;
    border-right: 0;

    padding: 5px;

    margin-right: 10px;
}

.search-container button {
    background-color: black;
    color: var(--gold);
    border: none;
    font-size: 1em;
    font-weight: 600;
}

.search-container input::placeholder {
    color: var(--gold);
    opacity: 50%;
}

.nav-btn {
    border: 1px solid var(--gold);
    background-color: transparent;
    font-size: 18px;
    color: #FAD42A;
    /* padding: 5px 15px; */
    border-radius: 5px;
}

.nav-btn:hover {
    cursor: pointer;
}

#search-btn {
    background-color: #323232;
    color: #FAD42A;
    border: 1px solid #FAD42A;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: inherit;
    font-weight: inherit;
}

.nav-link {
    text-decoration: none;
    color: #FAD42A;
    padding: 15px;
    text-align: center;
}

.nav-link:hover {
    opacity: 50%;
    transition-duration: 0.25s;
    cursor: pointer;
}

 /* Dropdown Button */
 .dropbtn, .signin-btn {
    background-color: #323232;
    color: #FAD42A;
    padding: 0.3em 1em;
    font-size: 1em;
    border: 2px solid #FAD42A;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #323232;
    color: #FAD42A;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    width: 100%;
    font-size: 1em;
  }

  .dropbtn svg {
    margin-right: 5px;
  }

  /* Links inside the dropdown */
  .dropdown-content a, .dropdown-content button {
    color: inherit;
    font-size: 16px;
    padding: 0.5em 1em;
    text-decoration: none;
    display: block;
    background: none;
    width: 100%;
    border: none;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover, .dropdown-content button:hover {
    background-color: #716f6f;
    cursor: pointer;
    }

  /* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    transition-duration: 0,25s;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #716f6f;
    transition-duration: 0.25s;
    cursor: pointer;
}

.signin-btn:hover {
    background-color: #716f6f;
    transition-duration: 0.25s;
    cursor: pointer;
}

.dark-mode-toggle {
    width: 3em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
}

/* Dark Mode Toggle */
.dark-mode-toggle:hover {
    cursor: pointer;
    opacity: 0.5;
    transition-duration: 0.25s;
}

.responsive-nav {
    display: none;
}

.responsive-nav-menu:hover {
    cursor: pointer;
}

/* Responsive Navbar */
@media screen and (max-width: 850px) {
    .navbar {
        display: none;
    }

    #logo {
        height: 75%;
        max-height: 40px;
        margin-left: 1em;
    }

    #logo:hover {
        opacity: 50%;
        transition-duration: 0.25s;
        cursor: pointer;
    }

    .responsive-nav-menu {
        margin-right: 1em;
    }

    .responsive-nav-menu:hover {
        opacity: 50%;
        transition-duration: 0.25s;
        cursor: pointer;
    }

    .responsive-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: #FAD42A;
        min-height: 50px;
        height: fit-content;
        top: 0;
        width: 100%;
        position: fixed;
        flex-direction: column;
        font-size: 18px;
        z-index: 1;
    }

    .responsive-nav__top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 0.6em;
    }

    .responsive-nav-links {
        display: none;
    }

    .responsive-nav-links.show {
        /* background-color: purple; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 0.5em;
    }

    .responsive-nav-links a {
        color: #FAD42A;
        /* padding: 15px; */
        text-decoration: none;
        display: block;
        text-align: center;
    }


    .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.5em;
    }

    .search-container input {
        background-color: black;
        color: #FAD42A;
        font-size: 18px;

        /* Border */

        border-color: #FAD42A;
        border-style:solid;

        border-radius: 0;

        border-top: 0;
        border-left: 0;
        border-right: 0;

        padding: 5px;

        max-width: 60vw;

    }

    .search-container button {
        background-color: black;
        color: #FAD42A;
        font-size: 18px;
        border: none;
        font-weight: normal;
    }

    .dropdown {
        margin-right: 15px;
        margin-bottom: 15px;
    }

}
