.category-page {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    color: var(--dark-1);
}

.dark-mode .category-page {
    color: var(--light-1);
}

.category-page__top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    width: 90%;
    margin: auto;

}

.category-page__top-bar h1 {
    margin-bottom: 0;
}

.top-bar__left {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-between; */
    width: 100%;
}

.top-bar__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.category-page__top-bar a {
    text-decoration: none;
}

.category-page__top-bar a:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.top-bar__right p {
    color: var(--dark-1);
}

.dark-mode .top-bar__right p {
    color: var(--light-1);
}


#video-count {
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-style: italic;
    color: inherit;
}

.category-page__videos {
    width: 90%;
    margin: auto;
    margin-top: 10px;
}

/* Adjust for smaller screens */
@media screen and (max-width: 850px) {
    .category-page__top-bar {
        flex-direction: column;
    }

    .top-bar__right {
        justify-content: flex-start;
    }
}
