.popup-background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.popup-container {
    background-color: #edece5;
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    position: inherit;
    width: 40%;
    height: 70vh;
    translate: 76% 15% 0px;
}

.remove-popup-container {
    margin: 0;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
}

.remove-popup-button {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    color: black;
}

.remove-popup-button:hover {
    cursor: pointer;
}

.popup-container h1 {
    margin: 0;
    padding: 0;
    padding-top: 2rem;
    text-align: center;
}

.update-pssw-form {
    margin: 2rem 2rem;
    line-height: 2rem;
}

.update-pssw-form__input-container {
    margin: 1rem 0rem;
}

.update-pssw-form p {
    font-size: 1.35rem;
    font-weight: bold;
}

.update-pssw-form input {
    padding: 0.25rem 0.5rem;
    width: 50%;
    border-width: 1px;
    border-radius: 5px;
}

.popup-submit-button {
    margin: 0;
    margin-left: 2rem;
    background-color: #FAD42A;
    border: 1px solid black;
    border-radius: 3px;
    padding: 0.35rem 1rem;
    font-size: calc(1rem + 0px);
}

.popup-submit-button:hover {
    cursor: pointer;
    box-shadow: 0 0 5px black;
}

@media only screen and (min-height: 1080px) {
    .popup-container {
        height: 70vh;
    }

    .remove-popup-button {
        font-size: 2rem !important;
    }

    .update-pssw-form {
        margin-top: 3rem;
    }

    .update-pssw-form__input-container {
        margin-top: 3rem;
    }

    .update-pssw-form__input-container p {
        font-size: 2rem;
    }

    .update-pssw-form__input-container input {
        font-size: 1.5rem;
    }

    .popup-submit-button {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1920px) {
    .popup-container {
        height: 70vh;
    }

    .remove-popup-button {
        font-size: 2rem !important;
    }

    .update-pssw-form {
        margin-top: 3rem;
    }

    .update-pssw-form__input-container {
        margin-top: 3rem;
    }

    .update-pssw-form__input-container p {
        font-size: 2rem;
    }

    .update-pssw-form__input-container input {
        font-size: 1.5rem;
    }

    .popup-submit-button {
        font-size: 1.5rem;
    }
}