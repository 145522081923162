.user-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.user-home__categories {
    display: flex;
    flex-direction: column;
    width: 90%;
    /* margin-bottom: 2em; */
}

.app-links {
    display: flex;
    align-items: center;
    width: 90%;
}

.app-links__text {
    font-weight: 600;
    font-size: 1.1em;
    color: var(--dark-1);
}

.dark-mode .app-links__text {
    color: var(--light-1);
}

.app-links img {
    height: 40px;
    width: auto;
    margin: 0 1em;
}

