/* Page */
.video-page {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 2em;
}

.video-page__body {
    width: 90%;
    display: flex;
    max-width: 1920px;
    justify-content: center;
    margin: auto;
}

/* Main Video */
.body__main-video {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
    margin: 0 2rem;
}

.body__main-video iframe {
    aspect-ratio: 16/9;
    width: 100%;
    max-width: 1920px;
}

.main-video__details {
    display: flex;
    flex-flow: column;
    background-color: var(--gray-2);
    color: var(--dark-1);
    border: 0px solid;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.dark-mode .main-video__details {
    background-color: var(--dark-2);
    color: var(--light-1);
}

#main-video__details__title {
    margin: 0;
    font-size: 1.5rem;
    color: inherit;
}

#main-video__details__row-date {
    /* margin: 0; */
    font-style: italic;
    font-weight: 500;
    color: inherit;
}

.main-video__details__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.main-video__details__row-interaction {
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    margin-right: 10px;
}

.main-video__details__row-interaction button {
    padding: 0;
    margin: 0.5em;
    color: inherit;
}

.main-video__details__row-interaction button:first-child {
    margin-left: 0;
}

.details__description {
    margin: 0;
    font-size: 0.9rem;
    color: inherit;
}

/* Suggested Videos */
.body__suggested-videos {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--dark-1);
}

.suggested-videos__header {
    background-color: var(--gray-2);
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: var(--dark-1);
    border-radius: 5px;
}

.dark-mode .suggested-videos__header {
    background-color: var(--dark-2);
    color: var(--light-1);
}

.suggested-videos__header h2 {
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;
    color: inherit;
}

.suggested-videos__videos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
}

.suggested-video {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    margin-bottom: 0.25em;
    text-decoration: none;
    flex-wrap: wrap;
    width: 100%;
}

.suggested-video:hover {
    opacity: 0.8;
    transition-duration: 0.25s;
}

.suggested-video > img {
    display: inline;
    width: 100%;
    aspect-ratio: 16/9;
    margin-right: 10px;
    margin-bottom: 1em;
    flex: 1;
    min-width: 150px;
    max-width: 300px;
    border-radius: 5px;
}

.suggested-video__video-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    flex: 1;
    color: var(--dark-1);
    text-decoration: none;
}

.dark-mode .suggested-video__video-details {
    color: var(--light-1);
}


.suggested-video__title {
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    margin: 0px;
    color: inherit;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Smaller Screen Size */
@media screen and (max-width: 1000px) {

    .video-page__body {
        flex-direction: column;
        width: 80%;
        margin-top: 2em;
    }

    .body__main-video {
        width: 100%;
        margin: 0;
    }

    .body__suggested-videos {
        width: 100%;
        margin: 1em 0;
    }


    .suggested-videos__videos {
        justify-content: flex-start;
    }

    .suggested-videos__header h2 {
        margin: 0;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .suggested-video {
        flex-direction: row;
        align-items: center;
    }

    .suggested-video > img {
        margin-right: 1em;
    }

    .suggested-video__video-details {
        align-items: flex-start;
    }

    .main-video__details__row-interaction > * {
        padding: 0;
    }

    .main-video__details .tag {
        font-size: 0.9rem;
    }

}

@media screen and (max-width: 450px) {
    .main-video__details__row {
        flex-direction: column;
        margin-bottom: 1em;
    }

    .video-page__body {
        margin-top: 1em;
    }
}
