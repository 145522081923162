body {
    font-family: 'Roboto';
    box-sizing: border-box;
    margin: 0;
}

.main-page {
    font-size: calc(1rem + 0px);
    margin-top: 0rem;
    z-index: -1;
}

.main-site {
    padding-top: 3.5rem;
    margin-left: 0vh;
    padding-left: 0.75rem;
    transition: 500ms;
}

.main-site.active {
    margin-left: 30vh;
    transition: 600ms;
}

.main-site h1 {
    margin: 0;
    padding: 0;
    font-size: calc(1rem + 16px);
}

.main-site p {
    margin: 0;
    /* font-size: calc(1rem + 2px); */
}

.overview-and-viewmore {
    display: flex;
    justify-content: space-between;
    margin: 0rem 0rem;
    margin-top: 2rem;
}

.boxed-text {
    font-size: calc(1rem + 0px);
    font-weight: bold;
    background-color: #C4C4C4;
    border-radius: 5px;
    border-width: 0;
    padding: .2rem 1.5rem;
    min-width: fit-content;
    max-width: fit-content;
    margin: 0rem 0.25rem;
}

.summary-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
    height: 100%;
}

.one-column {
    padding: 0rem 0.25rem;
}

.number-showcase {
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.action-title {
    margin-top: 4rem;
}

.quick-action-menu {
    margin-top: 1rem;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.quick-action-button {
    color: black;
    background-color: #FAD42A;
    min-width: fit-content;
    max-width: fit-content;
    margin: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    text-decoration: none;

    font-weight: bold;
    border-width: 0;
}

.quick-action-button:hover {
    cursor: pointer;
    box-shadow: 0 0 7px black;
}

.search-input {
    margin: 0.5rem 0.25rem;
    padding: 0.25rem 0rem;
    border: 2px solid #C4C4C4;
    border-radius: 3px;

    background-image: url('../../assets/search.png');
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: 0rem 0.25rem;
    padding-left: 1.1rem;
}

.search-input.long {
    margin: 0.5rem 0.25rem;
    padding: 0.25rem 0rem;
    border: 2px solid #C4C4C4;
    border-radius: 3px;
    width: 50%;

    background-image: url('../../assets/search.png');
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: 0rem 0.25rem;
    padding-left: 1.1rem;
}


/* The following are specifically for the 'View More button'
    of AdminDashboard.jsx */
.quick-action {
    width: fit-content;
    max-width: fit-content;
    text-decoration: none;
    color: black;
    background-color: #FAD42A;
    margin: 0%;
    margin-right: 7rem;
    padding: 0.15rem 1rem;
    border-radius: 5px;
}

.quick-action:hover {
    cursor: pointer;
    box-shadow: 0 0 7px black;
}

.dashboard-button {
    width: fit-content;
    max-width: fit-content;
    text-decoration: none;
    color: black;
    background-color: #FAD42A;
    margin: 0%;
    margin-right: 7rem;
    padding: 0.15rem 1rem;
    border-radius: 5px;
    font-weight: bold;
    border-color: transparent;
    font-size: 1rem;
}

.dashboard-button:hover {
    cursor: pointer;
    box-shadow: 0 0 7px black;
}

.bold {
    font-weight: bold;
}

.enlarge {
    font-size: calc(1rem + 2px);
}

@media only screen and (max-width: 1000px) {
    .main-site {
        padding-top: 3.5rem;
        margin-left: 0vh;
        padding-left: 0.75rem;
    }

    .main-site.active{
        margin-left: 15vh;
        transition: 600ms;
    }

    .boxed-text {
        font-size: calc(0.75rem + 2px);
    }

    .dashboard-button {
        font-size: calc(0.75rem + 1px);
    }
}

@media only screen and (max-width: 650px) {
    .main-site {
        padding-top: 4rem;
        margin-left: 0vh;
        padding-left: 0.75rem;
    }

    .main-site.active{
        margin-left: 4vh;
        transition: 600ms;
    }
}

@media only screen and (min-width: 2200px) {
    .boxed-text {
        font-size: calc(1.25rem + 2px);
    }

    .dashboard-button {
        font-size: calc(1rem + 1px);
    }
}

@media only screen and (min-height: 1080px) {
    .boxed-text {
        font-size: calc(2rem + 0px);
    }

    .dashboard-button {
        font-size: calc(2rem + 0px);
    }

    .main-site h1 {
        font-size: calc(2rem + 14px);
    }

    .main-site p {
        font-size: calc(1.5rem + 0px);
    }

    .search-input {
        font-size: 1.25rem;
        background-size: 1.25rem;
        background-position: 0rem 0.25rem;
        padding-left: 1.5rem;
    }

    .quick-action-button {
        font-size: 1.25rem;
    }

    .boxed-text.dashboard {
        font-size: 2.25rem;
    }
}

@media only screen and (min-width: 1920px) {
    .boxed-text {
        font-size: calc(2rem + 0px);
    }

    .dashboard-button {
        font-size: calc(2rem + 0px);
    }

    .main-site h1 {
        font-size: calc(2rem + 14px);
    }

    .main-site p {
        font-size: calc(1.5rem + 0px);
    }

    .search-input {
        font-size: 1.25rem;
        background-size: 1.25rem;
        background-position: 0rem 0.25rem;
        padding-left: 1.5rem;
    }

    .quick-action-button {
        font-size: 1.25rem;
    }

    .boxed-text.dashboard {
        font-size: 2.25rem;
    }
}